import type {
  AddAnswerToSessionRequest,
  AnsweredQuestionnaire,
  AnswerItemDto,
  ApiError,
  SubmitAnswersDataRequest
} from "@9amhealth/openapi";
import {
  LabOrderControllerService,
  QuestionnaireControllerService,
  QuestionnaireRef,
  StartSessionRequest,
  UserLifelineItemControllerService
} from "@9amhealth/openapi";
import { Cubit } from "blac";
import type {
  CustomQuestionnaireAnswer,
  CustomQuestionnaireAnswerType,
  CustomQuestionnaireAnswerValue,
  CustomQuestionnaireChoice,
  CustomQuestionnaireFilterAnswerOptions,
  CustomQuestionnaireResult,
  GlobalConfigs,
  MedicalInputData,
  MultipleTextInputData,
  QuestionnaireState
} from "src/state/QuestionnaireCubit/QuestionnaireState";
import {
  CustomQuestionnaireSchema,
  TimeCode
} from "src/state/QuestionnaireCubit/QuestionnaireState";

import appErrors from "src/constants/appErrors";
import { Condition } from "src/constants/conditions";
import { LoincCodingCode } from "src/constants/fhir";
import {
  ABBREVIATED_QUESTIONNAIRE_ID,
  FUNNEL_QUESTIONNAIRE_ELIGIBILITY,
  FUNNELS_NO_CACHE_QUESTIONNAIRES,
  MEDICAL_QUESTIONNAIRE_ID
} from "src/constants/misc";
import { addSentryBreadcrumb } from "src/lib/addSentryBreadcrumb";
import createTrackEvent from "src/lib/createTrackEvent";
import { DateFormats, dateLocal } from "src/lib/date";
import { featureFlags } from "src/lib/featureFlags";
import type { CustomObservation } from "src/lib/fhir";
import Fhir, { FhirObservationType } from "src/lib/fhir";
import {
  formatPhoneNumberNational,
  numbersAreEqual
} from "src/lib/formatPhoneNumber";
import { removeQuestionnaireKeywordsFromText } from "src/lib/removeQuestionnaireKeywordsFromText";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import type MultiStepFormCubit from "src/state/MultiStepFormCubit/MultiStepFormCubit";
import ProfileCubit from "src/state/ProfileCubit/ProfileCubit";
import { CustomFieldPropertyRegex } from "src/state/QuestionnaireStepCubit/CustomFields";
import type {
  QuestionFieldValidation,
  QuestionnaireField,
  QuestionnaireSelectChoice
} from "src/state/QuestionnaireStepCubit/QuestionnaireStepCubit";
import QuestionnaireStepCubit, {
  QuestionnaireFieldDataType,
  QuestionnaireType
} from "src/state/QuestionnaireStepCubit/QuestionnaireStepCubit";
import {
  apiMiddleware,
  historyState,
  loadingState,
  userPreferences,
  userState
} from "src/state/state";
import { TrackEvent } from "src/state/Track/TrackCubit";
import {
  ProfileAttributeSelectedGoals,
  ProfileAttributesKeys
} from "src/state/UserCubit/UserCubit";
import UserPreferencesCubit, {
  UserPreferenceKeys
} from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import type { TranslationKey } from "src/types/translationKey";
import { StorageController } from "../StorageBloc/StorageBloc";
import UserCubit from "../UserCubit/UserCubit";

export const HIDDEN_FIELD_NOT_APPLICABLE = "N/A";

export enum UserSelectedGoal {
  MEDICAL_TREATMENT = "option1_medical_treatment",
  REDUCE_MEDS = "option2_reduce_meds",
  NUTRITION_GOOD_HABITS = "option3_nutrition_good_habits",
  WEIGHT_LOSS = "option4_weight_loss",
  SLEEP_BETTER = "option5_sleep_better",
  NA = "N/A"
}

export enum UserSelectedDiagnosis {
  DIABETES = "option1_diabetes",
  PREDIABETES = "option2_prediabetes",
  POSSIBLE_DIABETES = "option3_possible_diabetes",
  HIGH_BLOOD_PRESSURE = "option4_high_blood_pressure",
  HIGH_CHOLESTEROL = "option5_high_cholesterol",
  NONE = "option6_none",
  NA = "N/A"
}

type QuestionnaireAnswerValue =
  | Record<string, number | string>
  | boolean
  | number
  | string
  | undefined;

export const questionnaireFieldValidation: Record<
  QuestionFieldValidation,
  RegExp
> = {
  // numbers between 4 and 16 with one optional decimal point
  a1c: /^[4-9]([,.][0-9])?$|^[1][0-6]([,.][0-9])?$/,

  // Two numbers separated by a slash, 2-3 digits each
  bloodpressure: /^[1-9]\d{1,2}\/[1-9]\d{1,2}$/,

  // Two or 3 digits, the first one is not 0
  bloodglucose: /^[1-9]\d{1,2}([.]\d{1,3})?$/,

  // Two or 3 digits, the first one is not 0
  weight: /^[1-9]\d{1,2}$/,

  // Two or 3 digits, the first one is not 0
  waistCircumference: /^[1-9]\d{1,2}$/
};

const questionHiddenFields = {
  hyperlipidemia: {
    choiceId: "RwvfbGaa9trK",
    hiddenField: "mjs"
  },
  hypertension: {
    choiceId: "Ct27aDq5UmIL",
    hiddenField: "bsu"
  },
  prediabetes: {
    choiceId: "8av8yhLUfV5K",
    hiddenField: "nsi"
  },
  t2d: {
    choiceId: "BkrbtDEDl95A",
    hiddenField: "ksh"
  },
  name: {
    choiceId: "nJWoQ4iXqW4S",
    hiddenField: "kwb"
  }
};
const { prediabetes, t2d, name, hypertension, hyperlipidemia } =
  questionHiddenFields;

export enum QuestionnaireStepTypeOutput {
  TEXT = "text",
  MULTIPLE_CHOICE = "multiple-choice",
  DROPDOWN = "dropdown",
  BOOLEAN = "boolean",
  CHOICE = "choice",
  INTEGER = "number",
  MEDICATION = "medication_amounts",
  MULTIPLE_TEXT = "multiple-text"
}

export type QuestionnaireValue =
  | Record<string, number | string>
  | string[]
  | boolean
  | number
  | string
  | undefined;

export type QuestionnaireLogicConditionVarInnerType =
  | "choice"
  | "constant"
  | "field"
  | "hidden"
  | "variable";

export type QuestionnaireLogicConditionOperation =
  | "always"
  | "and"
  | "begins_with"
  | "contains"
  | "equal"
  | "greater_equal_than"
  | "greater_than"
  | "is_not"
  | "is"
  | "lower_equal_than"
  | "lower_than"
  | "not_contains"
  | "not_equal"
  | "or";

export interface QuestionnaireLogicConditionVarInner {
  type: QuestionnaireLogicConditionVarInnerType;
  value: QuestionnaireValue;
}

export interface QuestionnaireLogicConditionVarInnerResolved {
  field?: QuestionnaireField;
  comparandValue: QuestionnaireValue[];
  match: boolean;
  subjectValue: QuestionnaireValue[];
}

export interface QuestionnaireLogicCondition {
  op: QuestionnaireLogicConditionOperation;
  vars: QuestionnaireLogicCondition[] | QuestionnaireLogicConditionVarInner[];
}

export interface QuestionnaireLogicDetailSet {
  target: {
    type: "variable";
    value: string;
  };
  value: {
    type: "constant" | "field" | "hidden" | "variable";
    value: string;
  };
}

export interface QuestionnaireLogicDetailJump {
  to: {
    type: "field" | "thankyou";
    value: string;
  };
}

export interface QuestionnaireLogicAction {
  action: "add" | "divide" | "jump" | "multiply" | "set";
  details: QuestionnaireLogicDetailJump | QuestionnaireLogicDetailSet;
  condition: QuestionnaireLogicCondition;
}

export interface QuestionnaireLogic {
  type: "field" | "hidden";
  ref?: string; // is undefined for logic that runs at beginning of questionnaire
  actions: QuestionnaireLogicAction[];
}

type HiddenFields = Record<string, string>;
export type CachedObject = Record<string, QuestionnaireValue>;

export const CACHE_KEY_PREFIX = "9am.form_fields.";

type KeyValueList = [string, number | string][];

export default class QuestionnaireCubit extends Cubit<QuestionnaireState> {
  customFormData: CachedObject = {};
  customFormVariables: CachedObject = {};
  allowTracking = true;
  preview = false;
  autoSkip = false;
  multiStepBloc?: MultiStepFormCubit;
  activeField?: QuestionnaireField;
  preventedJump?: QuestionnaireLogicDetailJump;
  dryRun = false;
  dryRunSteps: string[] = [];
  lastUpdated = 0;
  lastSaved = 0;
  disableAutoSubmit = false;
  instanceId = "";
  preventLoadingUserAnswers = false;
  allFieldStatus = new Map<string, boolean>();
  customKeyList = new Set<string>();
  onDataSentCallback?: (data: CustomQuestionnaireResult) => void;
  answerOverrides: CustomQuestionnaireAnswer[];
  filterAnswerOptions: CustomQuestionnaireFilterAnswerOptions[];

  constructor(
    id: string,
    options: {
      hiddenFields?: CachedObject;
      disableTracking?: boolean;
      preview?: boolean;
      autoSkip?: boolean;
      disableAutoSubmit?: boolean;
      instanceId?: string;
      preventLoadingUserAnswers?: boolean;
      onDataSent?: (data: CustomQuestionnaireResult) => void;
      answerOverrides?: CustomQuestionnaireAnswer[];
      filterAnswerOptions?: CustomQuestionnaireFilterAnswerOptions[];
    } = {}
  ) {
    super({
      formId: id,
      hiddenFields: options.hiddenFields ?? {},
      fields: [],
      endScreens: [],
      logic: [],
      formMeta: {},
      customFormData: {},
      error: "",
      logicSteps: []
    });

    this.answerOverrides = options.answerOverrides ?? [];
    this.filterAnswerOptions = options.filterAnswerOptions ?? [];
    this.onDataSentCallback = options.onDataSent;

    if (options.disableTracking) {
      this.allowTracking = false;
    }

    if (options.disableAutoSubmit) {
      this.disableAutoSubmit = true;
    }

    // Disable autoskip feature for now
    // if (options.autoSkip) this.autoSkip = true;
    this.autoSkip = false;

    this.instanceId = options.instanceId ?? "";
    this.preventLoadingUserAnswers = options.preventLoadingUserAnswers ?? false;
    this.preview = Boolean(options.preview);
    this.customFormVariables = options.hiddenFields ?? {};
    this.insertCachedValues();
    void this.loadCustomFormData();
  }

  staticAnswers: () => CustomQuestionnaireAnswer[] = () => {
    const additionalVars = {
      questionId: "questionnaire-vars",
      fieldType: "multiple-choice",
      fieldValue: this.collectVarsAsAnswers()
    } satisfies CustomQuestionnaireAnswer;

    const alwaysSendAnswers: CustomQuestionnaireAnswer[] = [
      {
        questionId: "r33X6Pmcju8c",
        fieldType: "text",
        fieldValue: dateLocal().format(DateFormats.ISO_FULL)
      }
    ];

    const legacyAnswers = this.generateLegacyAnswers();

    const allStatic: CustomQuestionnaireAnswer[] = [
      ...alwaysSendAnswers,
      ...legacyAnswers
    ];

    if (additionalVars.fieldValue.length > 0) {
      allStatic.push(additionalVars);
    }

    return allStatic;
  };

  generateLegacyAnswers = (): CustomQuestionnaireAnswer[] => {
    const legacyAnswers: CustomQuestionnaireAnswer[] = [];

    try {
      // Insurance Card Information
      // Get the values from the new question that has both fields
      const newInsQuestionValues: { groupno?: string; memberid?: string } = {};
      const formDataKeys = Object.keys(this.customFormData);
      for (const key of formDataKeys) {
        if (key.includes("groupno")) {
          newInsQuestionValues.groupno = String(this.customFormData[key]);
        }
        if (key.includes("memberid")) {
          newInsQuestionValues.memberid = String(this.customFormData[key]);
        }
      }
      if (newInsQuestionValues.groupno && newInsQuestionValues.memberid) {
        legacyAnswers.push({
          questionId: "WoKWiVuzOqoh",
          fieldType: "text",
          fieldValue: newInsQuestionValues.memberid
        });
        legacyAnswers.push({
          questionId: "x2MdbmI6mjTF",
          fieldType: "text",
          fieldValue: newInsQuestionValues.groupno
        });
      }
    } catch (error) {
      reportErrorSentry(error);
    }

    return legacyAnswers;
  };

  collectVarsAsAnswers = (): CustomQuestionnaireChoice[] => {
    const list: {
      value: string;
      choiceId: string;
    }[] = [];

    const sendFrom = this.customFormVariables;

    for (const key in sendFrom) {
      const value = sendFrom[key];
      if (value === undefined) continue;
      list.push({ value: String(value), choiceId: key });
    }

    return list;
  };

  static readonly parseTypeformDataFieldChoice = (
    answer: AnswerItemDto
  ): HiddenFields => {
    const keyValueMap: HiddenFields = {};
    const data = answer.fieldValue as CustomQuestionnaireChoice;

    if (data.choiceId === t2d.choiceId) {
      keyValueMap[t2d.hiddenField] =
        data.value === "Type 2 Diabetes" ? "1" : "0";
    }
    return keyValueMap;
  };

  static readonly parseTypeformDataFieldMultiChoice = (
    answer: AnswerItemDto
  ): HiddenFields => {
    const keyValueMap: HiddenFields = {};
    const data = answer.fieldValue as CustomQuestionnaireChoice[];

    for (const field of data) {
      switch (field.choiceId) {
        case prediabetes.choiceId:
          keyValueMap[prediabetes.hiddenField] = "1";
          break;
        case hypertension.choiceId:
          keyValueMap[hypertension.hiddenField] = "1";
          break;
        case hyperlipidemia.choiceId:
          keyValueMap[hyperlipidemia.hiddenField] = "1";
          break;
      }
    }
    return keyValueMap;
  };

  static readonly parseAnswers = (items?: AnswerItemDto[]): CachedObject => {
    let keyValueMap: CachedObject = {};
    if (!items) return keyValueMap;

    items.forEach((answer): void => {
      try {
        let newValues: HiddenFields = {};
        if (answer.fieldType === "choice") {
          newValues = QuestionnaireCubit.parseTypeformDataFieldChoice(answer);
        } else if (answer.fieldType === "multiple-choice") {
          newValues =
            QuestionnaireCubit.parseTypeformDataFieldMultiChoice(answer);
        } else if (
          answer.fieldType === "text" &&
          answer.questionId === name.choiceId
        ) {
          keyValueMap[name.hiddenField] = answer.fieldValue;
        }

        keyValueMap = {
          ...keyValueMap,
          ...newValues
        };
      } catch (e: unknown) {
        reportErrorSentry(e);
      }
    });

    return keyValueMap;
  };

  readonly generateOutputData = (): CustomQuestionnaireAnswer[] => {
    this.collectLogicSteps();
    const answersJson = Object.keys(this.customFormData)
      .filter((id) => {
        const field = this.getFieldByRef(id);
        const includedInLogic = field && this.dryRunSteps.includes(field.ref);
        const alwaysIncluded = field?.properties?.always_send_data ?? false;

        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        return includedInLogic || alwaysIncluded;
      })
      .map((key) => this.parseAnswer(key))
      .filter(Boolean);

    const overrides = this.expandSimpleAnswers(this.answerOverrides);

    return this.cleanAnswers([
      ...overrides,
      ...answersJson,
      ...this.staticAnswers()
    ]);
  };

  expandSimpleAnswers = (
    answers: CustomQuestionnaireAnswer[]
  ): CustomQuestionnaireAnswer[] => {
    const full: CustomQuestionnaireAnswer[] = [];

    const supportedFieldTypes = [
      QuestionnaireType.MULTIPLE_CHOICE,
      QuestionnaireType.DROPDOWN
    ];

    answers.forEach((answer) => {
      const field = this.getFieldByRef(answer.questionId);
      if (!field) return;
      if (!supportedFieldTypes.includes(field.type)) {
        // eslint-disable-next-line no-console
        console.warn(
          `Unsupported field type for answer overrides: ${
            field.type
          }. Supported types: ${supportedFieldTypes.join(", ")}`
        );
      } else {
        full.push(answer);
      }
    });

    return full;
  };

  cleanAnswers = (
    answers: CustomQuestionnaireAnswer[]
  ): CustomQuestionnaireAnswer[] => {
    const clean: CustomQuestionnaireAnswer[] = [];
    const questionIdAddedMap = new Set<string>();

    answers.forEach((answer) => {
      // remove duplicates, keep first
      if (questionIdAddedMap.has(answer.questionId)) return;
      questionIdAddedMap.add(answer.questionId);
      clean.push(answer);
    });

    return clean;
  };

  setFieldStatus = (id: string, hasIssue: boolean): void => {
    this.allFieldStatus.set(id, hasIssue);
  };

  postMessageDebuff = 0;
  sendDataToParent = (): void => {
    if (!userState.isTempUser) return;

    if (!this.state.formId) {
      reportErrorSentry("No formId in state");
      return;
    }

    const id = this.state.formId;

    window.clearTimeout(this.postMessageDebuff);

    this.postMessageDebuff = window.setTimeout(() => {
      const answersJson = this.generateOutputData();
      const allValid = true;

      const data: CustomQuestionnaireResult & { allValid: boolean } = {
        questionnaireRef: {
          id,
          type: "TYPEFORM"
        },
        answers: {
          schemaId: CustomQuestionnaireSchema.TYPEFORM,
          json: answersJson
        },
        allValid
      };

      window.parent.postMessage(
        {
          type: "questionnaireData",
          data
        },
        "*"
      );
    }, 200);
  };

  readonly onComplete = async (
    options: { saveData?: boolean } = {}
  ): Promise<false> => {
    const { saveData = true } = options;
    const { state } = this;

    if (!this.state.formId) {
      reportErrorSentry("No formId in state");
      return false;
    }

    try {
      // store completed questionnaires in memory
      userState.completedQuestionnairesMemoryCache.add(this.state.formId);
      // set in session
      sessionStorage.setItem(
        "completedQuestionnaires",
        JSON.stringify(Array.from(userState.completedQuestionnairesMemoryCache))
      );
    } catch (error) {
      reportErrorSentry(error);
    }

    const answersJson = this.generateOutputData();

    const data = {
      questionnaireRef: {
        id: this.state.formId,
        type: QuestionnaireRef.type.TYPEFORM
      },
      answers: {
        schemaId: CustomQuestionnaireSchema.TYPEFORM,
        json: answersJson
      },
      sessionId: this.currentSessionId
    } satisfies SubmitAnswersDataRequest;

    this.sendDataToParent();

    if (this.disableAutoSubmit || userState.isTempUser) {
      return false;
    }

    if (saveData && this.lastSaved !== this.lastUpdated) {
      loadingState.start(LoadingKey.saveQuestionnaireResult);
      this.lastSaved = this.lastUpdated;

      // save phone number, from phone input
      await this.savePhoneNumberToNotificationNumber();

      // save data
      try {
        // typecast because the OpenAPI docs expect some other fields that are actually not required
        await QuestionnaireControllerService.answerWithData(data);
        StorageController.removeItem(this.cacheKey);
        document.dispatchEvent(
          new CustomEvent("nineQuestionnaireSaved", {
            bubbles: true,
            composed: true,
            detail: { ...data, customFormVariables: this.customFormVariables }
          })
        );

        // delete session ID that has ended
        this.currentSessionId = undefined;

        // Clear cached data
        apiMiddleware.clearAll();

        this.onDataSentCallback?.(data);
      } catch (e: unknown) {
        this.emit({ ...state, error: appErrors.generic });
        reportErrorSentry(e);
      }

      loadingState.finish(LoadingKey.saveQuestionnaireResult);
    }
    return false;
  };

  readonly savePhoneNumberToNotificationNumber = async (): Promise<void> => {
    const steps = this.state.fields;
    const phoneField = steps.find(
      (item) => item.type === QuestionnaireType.PHONE_NUMBER
    );
    if (!phoneField) return;

    const phoneValue = this.customFormData[phoneField.id];
    const value = phoneValue
      ? formatPhoneNumberNational(String(phoneValue))
      : "";
    if (!value) return;

    try {
      const currentNumber = await ProfileCubit.getPhoneNumber();

      if (numbersAreEqual(currentNumber?.number, value)) return;

      await UserCubit.setNotificationNumber(value, {
        showLoading: false
      });
    } catch (error) {
      reportErrorSentry(error);
    }
  };

  get targetQuestionnaireName(): string {
    switch (this.state.formId) {
      case ABBREVIATED_QUESTIONNAIRE_ID:
        return "Eligibility ";
      case MEDICAL_QUESTIONNAIRE_ID:
        return "Medical ";
      default:
        return "";
    }
  }

  get trackingName(): TrackEvent {
    return createTrackEvent(
      `${this.targetQuestionnaireName}${TrackEvent.questionnaire}`
    );
  }

  readonly getQuestionnaireData = async (id?: string): Promise<AnyObject> => {
    if (!id)
      return {
        fields: [],
        thankyou_screens: [],
        logic: [],
        variables: {},
        welcome_screens: []
      };

    const featureFlagDisableQuestionnaireCache =
      featureFlags.disableQuestionnaireCache;

    const isInNoCacheList = FUNNELS_NO_CACHE_QUESTIONNAIRES.includes(id);
    const useCache =
      !isInNoCacheList &&
      !featureFlagDisableQuestionnaireCache &&
      Boolean(!this.preview);

    const overwriteLanguage = historyState.initialLocale;

    const response = await QuestionnaireControllerService.getQuestionnaire(
      "TYPEFORM",
      id,
      useCache,
      overwriteLanguage
    );
    return response.data;
  };

  private readonly fillCustomFormMultipleChoice = (
    answer: CustomQuestionnaireAnswer,
    field: QuestionnaireField
  ): void => {
    const choices = field.properties?.choices ?? [];
    if (answer.fieldType === "multiple-choice") {
      // set data for the list of selected choices
      this.customFormData[field.id] = (
        answer.fieldValue as CustomQuestionnaireChoice[]
      ).map((item) => {
        // set data for each selected choice
        this.customFormData[item.choiceId] = true;
        return item.choiceId;
      });
    } else if (answer.fieldType === "choice") {
      // set data for the selected as it would be a multi-select
      this.customFormData[field.id] = [
        choices.find((item) => {
          const match =
            item.label ===
            (answer.fieldValue as CustomQuestionnaireChoice).value;
          if (match) {
            // set data for the selected choice
            this.customFormData[item.id] = true;
          }
          return match;
        })?.id
      ].filter(Boolean);
    }
  };

  private readonly fillCustomFormDropdown = (
    answer: CustomQuestionnaireAnswer,
    field: QuestionnaireField
  ): void => {
    const choices = field.properties?.choices ?? [];
    // set data for the selected as it would be a multi-select
    this.customFormData[field.id] = [
      choices.find((item) => {
        const match = item.label === answer.fieldValue;
        if (match) {
          // set data for the selected choice
          this.customFormData[item.id] = true;
        }
        return match;
      })?.id
    ].filter(Boolean);
  };

  readonly fillCustomFormData = (
    answer: CustomQuestionnaireAnswer,
    fields: QuestionnaireField[]
  ): void => {
    const field = fields.find((item) => item.id === answer.questionId);
    if (!field) return;

    const choices = field.properties?.choices ?? [];

    // remove all cached data for this steps selected choices
    for (const c of choices) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete this.customFormData[c.id];
    }

    switch (field.type) {
      case QuestionnaireType.MULTIPLE_CHOICE:
        this.fillCustomFormMultipleChoice(answer, field);
        break;

      case QuestionnaireType.DROPDOWN:
        this.fillCustomFormDropdown(answer, field);
        break;

      case QuestionnaireType.DATE:
      case QuestionnaireType.LONG_TEXT:
      case QuestionnaireType.SHORT_TEXT:
      case QuestionnaireType.EMAIL:
      case QuestionnaireType.PHONE_NUMBER:
        this.customFormData[field.id] = String(answer.fieldValue);
        break;

      case QuestionnaireType.NUMBER:
      case QuestionnaireType.OPINION_SCALE:
        this.customFormData[field.id] = parseFloat(answer.fieldValue as string);
        break;

      case QuestionnaireType.YES_NO:
        this.customFormData[field.id] = answer.fieldValue ? "Yes" : "No";
        break;

      case QuestionnaireType.MEDICATION:
        this.customFormData[field.id] = answer.fieldValue as Record<
          string,
          string
        >;
        break;

      case QuestionnaireType.MULTIPLE_TEXT:
        this.customFormData[field.id] = answer.fieldValue as Record<
          string,
          string
        >;
        break;

      case QuestionnaireType.ZIP_CODE:
        this.customFormData[field.id] = String(answer.fieldValue);
        break;

      case QuestionnaireType.STATEMENT: {
        throw new Error(
          "Not implemented yet: QuestionnaireType.STATEMENT case"
        );
      }
      case QuestionnaireType.THANK_YOU: {
        throw new Error(
          "Not implemented yet: QuestionnaireType.THANK_YOU case"
        );
      }
    }
  };

  readonly loadAnsweredQuestionnaireData = async (
    fields: QuestionnaireField[]
  ): Promise<void> => {
    if (this.preventLoadingUserAnswers) return;

    const allAnswers = await userState.fetchAnsweredQuestionnaires();

    const onlyAnswers: CustomQuestionnaireAnswer[] = allAnswers
      .map((item) => item.answers.json as CustomQuestionnaireAnswer)
      .flat(1)
      .reverse();

    for (const answer of onlyAnswers) {
      this.fillCustomFormData(answer, fields);
    }

    this.collectLogicSteps();
    this.emit({
      ...this.state,
      customFormData: this.customFormData
    });
    this.collectLogicSteps({ addStepsToState: true });
  };

  readonly prefillUserData = async (
    fields: QuestionnaireField[]
  ): Promise<void> => {
    if (this.preventLoadingUserAnswers) return;
    const stored = this.getCachedValues();

    for (const field of fields) {
      const dataType = QuestionnaireStepCubit.checkDataType(field);

      let value: QuestionnaireValue | undefined;
      const key = field.id;

      switch (dataType) {
        case QuestionnaireFieldDataType.NAME:
          value = {
            firstname:
              userPreferences.state[UserPreferenceKeys.firstName] ??
              stored[`${key}.firstname`],
            lastname:
              userPreferences.state[UserPreferenceKeys.lastName] ??
              stored[`${key}.lastname`]
          } as Record<string, number | string>;
          break;
        case QuestionnaireFieldDataType.DATE_OF_BIRTH:
          value = userPreferences.state[UserPreferenceKeys.userDateOfBirth];
          break;
        case QuestionnaireFieldDataType.ZIP_CODE:
          value = userPreferences.state[UserPreferenceKeys.zip];
          break;
        default:
          break;
      }

      if (key && typeof value !== "undefined") {
        this.saveValue(key, value);
      }

      // handle special cases
      if (dataType === QuestionnaireFieldDataType.ASSIGNED_SEX) {
        const match = field.properties?.choices?.find(
          (f) =>
            f.label.toLowerCase() ===
            userPreferences.state[UserPreferenceKeys.userSex]?.toLowerCase()
        )?.id;

        if (match) {
          this.saveValue(key, [match]);
          this.saveValue(match, true);
        }

        break;
      }
    }
  };

  readonly updateFieldMetadata = (
    field: QuestionnaireField
  ): QuestionnaireField => {
    const filterForField = this.filterAnswerOptions.find(
      (filter) => filter.questionId === field.id
    );

    if (filterForField && field.properties && field.properties.choices) {
      const choices = field.properties.choices ?? [];
      field.properties.choices = choices.filter((choice) => {
        const match = filterForField.includeOptions.find(
          (filterChoice) =>
            filterChoice === choice.id || filterChoice === choice.ref
        );

        if (match) {
          return true;
        }

        return false;
      });
    }

    return field;
  };

  readonly loadCustomFormData = async (): Promise<void> => {
    loadingState.start(LoadingKey.questionnaire);
    const id = this.state.formId;
    try {
      const data = await this.getQuestionnaireData(String(id));
      if (!data.fields) return;
      const fields = (data.fields as QuestionnaireField[])
        .map(QuestionnaireStepCubit.prepareFieldData)
        .map(this.updateFieldMetadata);
      const endScreens = (data.thankyou_screens as QuestionnaireField[]).map(
        QuestionnaireStepCubit.prepareFieldData
      );
      const logic = (data.logic ?? []) as QuestionnaireLogic[];
      const { actions = [] } =
        logic.find((item) => item.type === "hidden") ?? {};

      const hiddenFields: CachedObject = {
        funnel_link: "not_set"
      };
      const { hidden = [] } = data;
      for (const key of hidden as string[]) {
        hiddenFields[key] = undefined;
      }

      const [variables] = await Promise.all([
        this.loadVariableData(data.variables as CachedObject),
        this.loadAnsweredQuestionnaireData(fields),
        this.collectHiddenFieldData(hiddenFields),
        this.prefillUserData(fields),
        this.startSession()
      ]);

      this.customFormVariables = variables;

      const globalConfigs = this.parseGlobalConfigs(fields);

      this.collectLogicSteps();

      if (globalConfigs.disableDataPrefill) {
        // clear all data if data prefill is disabled
        this.customFormData = {};
      }

      this.emit({
        ...this.state,
        formMeta: data,
        fields,
        logic,
        formId: String(this.state.formId),
        hiddenFields,
        endScreens,
        customFormData: this.customFormData,
        globalConfigs
      });
      this.lastUpdated = Date.now();

      // eslint-disable-next-line @typescript-eslint/prefer-destructuring
      this.activeField = fields[0];
      for (const action of actions) {
        this.runLogicActions(action);
      }

      this.populateCalculatedVariables();
      this.insertDefaultValues();
      if (this.autoSkip) {
        this.autoContinue();
      }

      this.collectLogicSteps({ addStepsToState: true });
    } catch (e: unknown) {
      reportErrorSentry(e);

      const apiError = e as ApiError;
      this.emit({
        ...this.state,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        error: `${apiError.body?.code ?? 0}`
      });
    }
    loadingState.finish(LoadingKey.questionnaire);
  };

  parseGlobalConfigs = (fields: QuestionnaireField[]): GlobalConfigs => {
    const globalConfigs: GlobalConfigs = {};

    for (const field of fields) {
      if (field.properties?.global_disable_data_prefill) {
        globalConfigs.disableDataPrefill = true;
      }
    }

    return globalConfigs;
  };

  readonly insertDefaultValues = (): void => {
    const fieldsWithDefaultValue = this.state.fields.filter(
      (field) => field.properties?.default_value
    );

    for (const field of fieldsWithDefaultValue) {
      const currentValue = this.customFormData[field.id];
      if (currentValue) continue;

      const defaultValue = field.properties?.default_value ?? "";
      const value = this.replacePlaceholders(defaultValue);
      this.customFormData[field.id] = value;
    }

    this.emit({
      ...this.state,
      customFormData: this.customFormData
    });
  };

  readonly loadLabValues = async (): Promise<KeyValueList> => {
    const values: KeyValueList = [];
    if (userState.isTempUser) return values;

    try {
      const data = await UserLifelineItemControllerService.getLifelineItems([
        "hl7_fhir_r4_lab_value"
      ]);

      const allObservations: CustomObservation[] = data.data.map(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        (userLifelineItem): CustomObservation => ({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          ...userLifelineItem.deserializedPayload.observation,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
          sourceType: userLifelineItem.deserializedPayload.source?.type ?? "",
          id: userLifelineItem.id
        })
      );

      const diagnosticObservations = Fhir.filterObservationsBySource(
        allObservations,
        [
          FhirObservationType.diagnostics,
          FhirObservationType.bioReferenceLabs,
          FhirObservationType.api
        ]
      );
      const observations = Fhir.filterUniqueObservationsByCode(
        diagnosticObservations
      );

      for (const observation of observations) {
        if (
          Fhir.getLoincCoding(observation.code.coding)?.code ===
          LoincCodingCode.hba1c
        ) {
          const value = observation.valueQuantity?.value;
          const date =
            observation.effectiveDateTime &&
            Fhir.getObservationDate(observation).format(DateFormats.ISO_FULL);

          if (value) values.push(["lab_a1c_value", value]);
          if (date) values.push(["lab_a1c_date", date]);
        }
      }
    } catch (e: unknown) {
      reportErrorSentry(e);
    }

    return values;
  };

  readonly loadLabOrderProviders = async (): Promise<KeyValueList> => {
    const values: KeyValueList = [];
    if (userState.isTempUser) return values;

    try {
      const response =
        await LabOrderControllerService.availableLabOrderProviders();
      const labProviders = response.data.labOrderProviders as string[];

      values.push(["lab_order_providers", labProviders.join(";")]);
      const homePhlebotomyAvailable =
        UserPreferencesCubit.isHomePhlebotomySupported(labProviders);
      values.push([
        "home_phlebotomy_available",
        homePhlebotomyAvailable ? "yes" : "no"
      ]);
    } catch (e: unknown) {
      reportErrorSentry(e);
    }

    return values;
  };

  checkChoiceKeyMapContainsId = (
    choiceKeyMap: Record<string, string[]>,
    id: string
  ): string | undefined => {
    for (const key in choiceKeyMap) {
      if (choiceKeyMap[key].includes(id)) return key;
    }
    return undefined;
  };

  extractDataFromAnswers = async (
    questionnaireIds: string[],
    questionIds: string[],
    choiceKeyMap: Record<string, string[]>
  ): Promise<string> => {
    const answered = await userState.loadUserAnsweredQuestionnaires();
    if (!answered) return "";

    const result: string[] = [];

    // get all answers that match the question ids from the questionnaires that match the questionnaire ids
    const applicableQuestionnaires: AnsweredQuestionnaire[] = answered
      .filter((answeredQuestionnaire) =>
        questionnaireIds.includes(answeredQuestionnaire.questionnaireRef.id)
      )
      // use only the newest of those questionnaires
      .sort((a, b) => b.timestamp.localeCompare(a.timestamp))
      .slice(0, 1);

    const applicableAnswers = applicableQuestionnaires.flatMap(
      (answeredQuestionnaire) => {
        const answers = (answeredQuestionnaire.answers.json ??
          []) as CustomQuestionnaireAnswer[];
        return answers.filter((answer) =>
          questionIds.includes(answer.questionId)
        );
      }
    );

    // parse each applicable answer
    for (const answer of applicableAnswers) {
      if (answer.fieldType === "choice") {
        const id = (answer.fieldValue as CustomQuestionnaireChoice).choiceId;
        const matchingKey = this.checkChoiceKeyMapContainsId(choiceKeyMap, id);
        if (matchingKey) {
          result.push(matchingKey);
        }
      } else if (answer.fieldType === "multiple-choice") {
        (answer.fieldValue as CustomQuestionnaireChoice[]).forEach((choice) => {
          const id = choice.choiceId;
          const matchingKey = this.checkChoiceKeyMapContainsId(
            choiceKeyMap,
            id
          );
          if (matchingKey) {
            result.push(matchingKey);
          }
        });
      }
    }

    if (result.length === 0) return HIDDEN_FIELD_NOT_APPLICABLE;

    return result.join(";");
  };

  loadUserFunnelLink = async (): Promise<KeyValueList> => {
    let link = userPreferences.lastSignupFunnel ?? "";
    console.log('loadUserFunnelLink', link);

    // check url for current funnel key
    const match = /signup\/([a-z-]+)\//.exec(location.href);
    if (match) {
      // eslint-disable-next-line @typescript-eslint/prefer-destructuring
      link = match[1];
    }

    console.log('loadUserFunnelLink', link);

    link = link.toLowerCase();
    link = link.replace(/ /g, "_");

    return [["funnel_link", link]];
  };

  // Load primary goal
  loadSelectedGoals = async (): Promise<KeyValueList> => {
    const choiceKeyMap: Record<
      ProfileAttributeSelectedGoals,
      UserSelectedGoal
    > = {
      "Get expert medical advice and treatment":
        UserSelectedGoal.MEDICAL_TREATMENT,
      "Reduce or prevent adding medications": UserSelectedGoal.REDUCE_MEDS,
      "Improve my nutrition and create good habits":
        UserSelectedGoal.NUTRITION_GOOD_HABITS,
      "Lose weight and optimize my health": UserSelectedGoal.WEIGHT_LOSS,
      "Sleep better and increase my daily energy": UserSelectedGoal.SLEEP_BETTER
    };

    const attributes = await userState.getProfileAttributes([
      ProfileAttributesKeys.programSelectedGoals
    ]);
    const goalAttr = attributes?.[ProfileAttributesKeys.programSelectedGoals];

    const primary = goalAttr?.find((goal) => goal.isPrimary);

    let selected = "N/A";

    if (primary && primary.name in choiceKeyMap) {
      selected = choiceKeyMap[primary.name];
    }

    return [["selected_goals", selected]];
  };

  loadSelectedDiagnosis = async (): Promise<KeyValueList> => {
    const choiceKeyMap: Partial<Record<Condition, UserSelectedDiagnosis>> = {
      [Condition.unknownDiabetes]: UserSelectedDiagnosis.DIABETES,
      [Condition.diabetesType1]: UserSelectedDiagnosis.DIABETES,
      [Condition.diabetesType2]: UserSelectedDiagnosis.DIABETES,
      [Condition.gestationalDiabetes]: UserSelectedDiagnosis.DIABETES,
      [Condition.prediabetes]: UserSelectedDiagnosis.PREDIABETES,
      [Condition.highBloodPressure]: UserSelectedDiagnosis.HIGH_BLOOD_PRESSURE,
      [Condition.highColesterol]: UserSelectedDiagnosis.HIGH_CHOLESTEROL
    };

    const attributes = await userState.getProfileAttributes([
      ProfileAttributesKeys.medicalConditions
    ]);
    const conditionAttr =
      attributes?.[ProfileAttributesKeys.medicalConditions] ?? [];

    let selected = "N/A";

    if (conditionAttr.length > 0) {
      selected = conditionAttr
        .map((condition) => {
          return choiceKeyMap[condition.condition.name];
        })
        .filter(Boolean)
        .join(";");
    }

    if (selected === "N/A") {
      // check if the user answered the eligibility questionnaire
      const answered = await userState.loadUserAnsweredQuestionnaires();
      const answeredEligibility = answered?.find(
        (answeredQuestionnaire) =>
          answeredQuestionnaire.questionnaireRef.id ===
          FUNNEL_QUESTIONNAIRE_ELIGIBILITY
      );
      // if the user answered the eligibility questionnaire, set the selected diagnosis to none
      if (answeredEligibility) {
        selected = UserSelectedDiagnosis.NONE;
      }
    }

    return [["selected_diagnosis", selected]];
  };

  loadFirstName = async (): Promise<KeyValueList> => {
    const firstName = userPreferences.state[UserPreferenceKeys.userFirstName];
    return [["first_name", firstName ?? ""]];
  };

  loadSelectedValuesOnly = async (
    fn: () => Promise<KeyValueList>
  ): Promise<string[]> => {
    const response = await fn();
    const values = response.map(([, value]) => value);
    return String(values[0]).split(";");
  };

  readonly collectHiddenFieldData = async (
    fields: CachedObject
  ): Promise<void> => {
    const keys = Object.keys(fields);
    const promises: Promise<KeyValueList>[] = [];

    const loadLabs =
      keys.includes("lab_a1c_value") || keys.includes("lab_a1c_date");

    if (loadLabs) {
      promises.push(this.loadLabValues());
    }

    const labOrderProviders =
      keys.includes("lab_order_providers") ||
      keys.includes("home_phlebotomy_available");
    if (labOrderProviders) {
      promises.push(this.loadLabOrderProviders());
    }

    const funnelLink = keys.includes("funnel_link");
    if (funnelLink) {
      promises.push(this.loadUserFunnelLink());
    }

    const selectedGoals = keys.includes("selected_goals");
    if (selectedGoals) {
      promises.push(this.loadSelectedGoals());
    }

    const selectedDiagnosis = keys.includes("selected_diagnosis");
    if (selectedDiagnosis) {
      promises.push(this.loadSelectedDiagnosis());
    }

    const firstName = keys.includes("first_name");
    if (firstName) {
      promises.push(this.loadFirstName());
    }

    const data = await Promise.all(promises);
    const flatList = data.flat(1);

    for (const [k, v] of flatList) {
      fields[k] = v;
    }
  };

  userHeightField: QuestionnaireField | undefined = undefined;

  get userHeight(): number {
    const heightField =
      this.userHeightField ??
      this.state.fields.find((field) => field.properties?.height_field);
    if (!this.userHeightField) this.userHeightField = heightField;

    return heightField
      ? Number(this.parseAnswerOnlyValue(heightField).value ?? 0)
      : 0;
  }

  userWeightField: QuestionnaireField | undefined = undefined;

  get userWeight(): number {
    const weightField =
      this.userWeightField ??
      this.state.fields.find((field) => field.properties?.weight_field);
    if (!this.userWeightField) this.userWeightField = weightField;

    return weightField
      ? Number(this.parseAnswerOnlyValue(weightField).value ?? 0)
      : 0;
  }

  readonly populateCalculatedVariables = (): void => {
    for (const [key] of Object.entries(this.customFormVariables)) {
      switch (key) {
        // bmi from height and weight
        case "prefilled_bmi":
          this.customFormVariables[key] =
            this.userWeight / Math.pow(this.userHeight / 100, 2);
          break;
      }
    }
  };

  readonly loadVariableData = async (
    variables: CachedObject = {}
  ): Promise<CachedObject> => {
    const result: AnyObject = {};

    const preferences = await userPreferences.loadUserPreferences();

    for (const [key, value] of Object.entries(variables)) {
      let parsedValue = value;

      try {
        if (key === "prefilled_age") {
          const dob = preferences?.[UserPreferenceKeys.userDateOfBirth];
          if (dateLocal(dob).isValid()) {
            parsedValue = dateLocal().diff(dateLocal(dob), "years");
          }
        }
      } catch (error: unknown) {
        reportErrorSentry(error);
      }

      result[key] = parsedValue;
    }
    return result;
  };

  readonly isFinishStep = (field?: QuestionnaireField): boolean => {
    // statement screens with the button text "Finish" or "Go Back" are considered to be the end of the questionnaire
    const isFinishStatement =
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      field?.properties?.ending ||
      (field?.type === QuestionnaireType.STATEMENT &&
        (field.properties?.button_text === "Finish" ||
          field.properties?.button_text === "Go Back"));

    // Thank you pages are always the end of the questionnaire
    const isThankYou = field?.type === QuestionnaireType.THANK_YOU;

    // check if any of the above is true
    return [isFinishStatement, isThankYou].some(Boolean);
  };

  readonly isFirstStep = (field?: QuestionnaireField): boolean => {
    const [firstStep] = this.state.fields;
    return firstStep.id === field?.id;
  };

  readonly runValidations = (field?: QuestionnaireField): TranslationKey[] => {
    if (!field) return [];

    const fieldValue = this.parseAnswerOnlyValue(field.id);
    const fieldRequired = field.validations?.required;
    const { value } = fieldValue;

    if ((value === undefined || value === "") && fieldRequired)
      return ["error_validation_required"];

    if (this.isFinishStep(field)) return [];

    const validationKey = field.properties?.validation_key;

    if (!validationKey) return [];
    const validation = questionnaireFieldValidation[validationKey] as
      | RegExp
      | undefined;

    if (!validation) return [];

    if (value === undefined || value === "") return [];

    const matchesRegex = validation.exec(String(value));

    if (matchesRegex === null) {
      return [`error_validation_${validationKey}`];
    }

    return [];
  };

  readonly autoContinue = (): void => {
    const activeIsFinish = this.isFinishStep(this.activeField);
    if (activeIsFinish) return;

    this.collectLogicSteps();

    let setRef = "";

    let steps = this.dryRunSteps
      .map((item) => this.getFieldByRef(item))
      .filter(Boolean);

    steps = [...steps];

    // filter out untouched fields
    const firstCannotSkip = steps.find(
      (step) => !this.canSkipField(step) && !this.isFinishStep(step)
    );

    if (firstCannotSkip) {
      setRef = firstCannotSkip.ref;
    } else {
      const lastStep = steps[steps.length - 1] as
        | QuestionnaireField
        | undefined;
      if (lastStep?.properties?.question_reference) {
        setRef = lastStep.properties.question_reference;
      }

      if (!setRef) {
        const notFinalSteps = steps.filter((step) => !this.isFinishStep(step));
        const lastNotFinal = notFinalSteps[notFinalSteps.length - 1] as
          | QuestionnaireField
          | undefined;
        if (lastNotFinal) setRef = lastNotFinal.ref;
      }
    }

    // continue on optional fields
    const setIndex = this.dryRunSteps.indexOf(setRef);
    const activeIndex = this.activeField
      ? this.dryRunSteps.indexOf(this.activeField.ref)
      : 0;
    if (this.activeField && activeIndex > setIndex) {
      setRef = this.activeField.ref;
    }

    if (setRef) {
      this.handleJump(
        {
          to: {
            type: "field",
            value: setRef
          }
        },
        true
      );
    }
    this.collectLogicSteps({ addStepsToState: true });
  };

  getFieldByXCache: Record<string, QuestionnaireField | undefined> = {};

  readonly getFieldByRef = (ref: string): QuestionnaireField | undefined => {
    const cached = this.getFieldByXCache[ref];
    if (cached) return cached;
    const found = this.state.fields.find(
      (item) => item.ref === ref || item.id === ref
    );

    if (found) {
      this.getFieldByXCache[ref] = found;
      return found;
    }
  };

  static mapQuestionTypeToAnswerType = (
    qt: QuestionnaireType,
    singleSelect = false,
    medicationField = false
  ): CustomQuestionnaireAnswerType | string => {
    switch (qt) {
      case QuestionnaireType.SHORT_TEXT:
        return medicationField
          ? QuestionnaireStepTypeOutput.MEDICATION
          : QuestionnaireStepTypeOutput.TEXT;
      case QuestionnaireType.LONG_TEXT:
      case QuestionnaireType.EMAIL:
      case QuestionnaireType.DATE:
      case QuestionnaireType.ZIP_CODE:
      case QuestionnaireType.PHONE_NUMBER:
        return QuestionnaireStepTypeOutput.TEXT;
      case QuestionnaireType.DROPDOWN:
        return QuestionnaireStepTypeOutput.CHOICE;
      case QuestionnaireType.MULTIPLE_CHOICE:
        return singleSelect
          ? QuestionnaireStepTypeOutput.CHOICE
          : QuestionnaireStepTypeOutput.MULTIPLE_CHOICE;
      case QuestionnaireType.NUMBER:
      case QuestionnaireType.OPINION_SCALE:
        return QuestionnaireStepTypeOutput.INTEGER;
      case QuestionnaireType.YES_NO:
        return QuestionnaireStepTypeOutput.BOOLEAN;
      case QuestionnaireType.MEDICATION:
        return QuestionnaireStepTypeOutput.MEDICATION;
      case QuestionnaireType.MULTIPLE_TEXT:
        return QuestionnaireStepTypeOutput.MULTIPLE_TEXT;
      default:
        reportErrorSentry(
          `[mapQuestionTypeToAnswerType] Unknown questionnaire type: ${qt}`
        );
        return qt;
    }
  };

  get cacheKey(): string {
    if (this.state.formId) {
      return `${CACHE_KEY_PREFIX}${this.state.formId}${this.instanceId}`;
    }

    return `${CACHE_KEY_PREFIX}-${this.instanceId}`;
  }

  readonly insertCachedValues = (): void => {
    const values = this.getCachedValues();
    this.customFormData = values;
    this.collectLogicSteps();
    this.emit({
      ...this.state,
      customFormData: this.customFormData
    });
    this.lastUpdated = Date.now();
    this.collectLogicSteps({ addStepsToState: true });

    this.sendDataToParent();
  };

  readonly getCachedValues = (): CachedObject =>
    StorageController.activeUserId
      ? (JSON.parse(StorageController.getItem(this.cacheKey) ?? "{}") as Record<
          string,
          boolean | string
        >)
      : {};

  readonly saveValue = (key?: string, value?: QuestionnaireValue): void => {
    if (!key) return;
    // skip if value matches exact
    if (this.customFormData[key] === value) return;
    // skip if value is false and current value is undefined
    if (value === false && this.customFormData[key] === undefined) return;

    const customFormDataClone = { ...this.customFormData };

    const oldValue = customFormDataClone[key];
    const newValue = value;
    const hasChanged = oldValue !== newValue;

    if (hasChanged) {
      customFormDataClone[key] = value;
      if (Array.isArray(oldValue)) {
        for (const item of oldValue) {
          customFormDataClone[item] = false;
        }
      }
      if (Array.isArray(value)) {
        for (const item of value) {
          if (item) customFormDataClone[item] = true;
        }
      }

      this.collectLogicSteps();
      this.lastUpdated = Date.now();

      if (Array.isArray(oldValue)) {
        for (const k of oldValue) {
          customFormDataClone[k] = false;
        }
      }
      if (Array.isArray(newValue)) {
        for (const k of newValue) {
          customFormDataClone[k] = true;
        }
      }

      const allData = this.getCachedValues();
      allData[key] = value === false ? undefined : value;
      StorageController.setItem(this.cacheKey, JSON.stringify(allData));

      this.customFormData = customFormDataClone;
      this.emit({
        ...this.state,
        customFormData: customFormDataClone
      });

      // check if key has a separator "."
      const keyParts = key.split(".");
      if (keyParts.length > 1) {
        const [parentKey, localKey] = keyParts;
        const objectRepresentation = customFormDataClone[parentKey] ?? {};

        // update "object value" representation if the current key is with a separator
        if (objectRepresentation && typeof objectRepresentation === "object") {
          const valueClone = { ...objectRepresentation } as AnyObject;
          valueClone[localKey] = value;
          this.saveValue(parentKey, { ...valueClone });
        }
      }
      this.sendDataToParent();
    }

    this.collectLogicSteps({ addStepsToState: true });
  };

  readonly getValue = (key: string): QuestionnaireValue[] | undefined => {
    const value = this.customFormData[key];
    if (
      typeof value === "string" ||
      typeof value === "boolean" ||
      typeof value === "number" ||
      value instanceof Object
    ) {
      return [value];
    }
    return value;
  };

  readonly parseAnswer = (
    keyOrField: QuestionnaireField | string
  ): CustomQuestionnaireAnswer | undefined => {
    const field =
      typeof keyOrField === "string"
        ? this.getFieldByRef(keyOrField)
        : keyOrField;
    if (!field) return;

    const value = this.customFormData[field.id];

    let fieldValue:
      | CustomQuestionnaireAnswerValue
      | MedicalInputData
      | string[]
      | undefined = value;
    if (fieldValue === "" || fieldValue === undefined) return;

    const singleSelect = field.properties?.allow_multiple_selection === false;
    const medicationField = field.properties?.medication_field === true;

    const fieldType = QuestionnaireCubit.mapQuestionTypeToAnswerType(
      field.type,
      singleSelect,
      medicationField
    );

    // parse each field depending on its type
    switch (field.type) {
      case QuestionnaireType.MULTIPLE_TEXT:
        fieldValue = QuestionnaireCubit.parseFieldValueMultipleText(
          field,
          this.customFormData
        );
        break;
      case QuestionnaireType.MEDICATION:
        fieldValue = QuestionnaireCubit.parseFieldValueMedication(
          field,
          this.customFormData
        );
        break;
      case QuestionnaireType.DROPDOWN:
        fieldValue = QuestionnaireCubit.parseFieldValueDropdown(
          field,
          this.customFormData
        );
        break;
      case QuestionnaireType.MULTIPLE_CHOICE:
        fieldValue = QuestionnaireCubit.parseFieldValueMultipleChoice(
          field,
          this.customFormData
        );
        if (singleSelect) {
          // adding possible type undefined because TS assumes that the index 0 is always defined
          fieldValue = fieldValue[0] as CustomQuestionnaireChoice | undefined;
        }
        break;
      case QuestionnaireType.NUMBER:
      case QuestionnaireType.OPINION_SCALE:
        fieldValue = QuestionnaireCubit.parseFieldValueNumber(
          field,
          this.customFormData
        );
        break;

      case QuestionnaireType.YES_NO:
        fieldValue = QuestionnaireCubit.parseFieldValueBoolean(
          field,
          this.customFormData
        );
        break;
      default:
        break;
    }

    if (fieldValue === undefined) return;

    return {
      questionId: field.id,
      fieldType,
      fieldValue: fieldValue as CustomQuestionnaireAnswerValue
    };
  };

  readonly parseAnswerOnlyValue = (
    keyOrField: QuestionnaireField | string,
    options: { joinListBy?: string } = {}
  ): {
    value: QuestionnaireAnswerValue;
    multiple: boolean;
  } => {
    const { joinListBy = "\n" } = options;
    const fullParsedAnswer = this.parseAnswer(keyOrField);

    const result: {
      value: QuestionnaireAnswerValue;
      multiple: boolean;
    } = {
      value: undefined,
      multiple: false
    };

    if (!fullParsedAnswer) {
      // check overwrides for possible value
      if (typeof keyOrField === "string") {
        const field = this.getFieldByRef(keyOrField);
        if (field) {
          const prefilledValue = this.answerOverrides.find(
            (f) => f.questionId === field.id
          );
          if (prefilledValue) {
            const fieldValue =
              prefilledValue.fieldValue as QuestionnaireAnswerValue;
            const fieldValueString =
              typeof fieldValue === "object" ? fieldValue.value : fieldValue;
            result.value = fieldValueString;
          }
        }
      }
      return result;
    }

    if (typeof fullParsedAnswer.fieldValue === "string") {
      const properDelimiter = fullParsedAnswer.fieldValue.replace(",", ".");
      const toNumber = parseFloat(properDelimiter);

      // if the value looks like a number, return it as number
      if (`${toNumber}` === properDelimiter) {
        result.value = toNumber;
        return result;
      }

      result.value = properDelimiter;
      return result;
    }

    if (
      typeof fullParsedAnswer.fieldValue === "object" &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, no-prototype-builtins
      Object(fullParsedAnswer.fieldValue).hasOwnProperty("value")
    ) {
      result.value = (
        fullParsedAnswer.fieldValue as CustomQuestionnaireChoice
      ).value;
      return result;
    }

    if (Array.isArray(fullParsedAnswer.fieldValue)) {
      result.value = fullParsedAnswer.fieldValue
        .map((v) => v.value)
        .join(joinListBy);
      result.multiple = true;
      return result;
    }

    if (
      typeof fullParsedAnswer.fieldValue === "number" ||
      typeof fullParsedAnswer.fieldValue === "boolean"
    ) {
      result.value = fullParsedAnswer.fieldValue;
      return result;
    }

    if (
      fullParsedAnswer.fieldValue instanceof Object &&
      !("choiceId" in fullParsedAnswer.fieldValue)
    ) {
      result.value = fullParsedAnswer.fieldValue;
      return result;
    }

    reportErrorSentry(
      `Unknown field value type ${typeof fullParsedAnswer.fieldValue}`
    );
    return result;
  };

  static parseFieldValueMultipleText = (
    field: QuestionnaireField,
    formData: CachedObject
  ): MultipleTextInputData => {
    return (formData[field.id] ?? {}) as MultipleTextInputData;
  };

  static parseFieldValueMedication = (
    field: QuestionnaireField,
    formData: CachedObject
  ): MedicalInputData => {
    const value = (formData[field.id] ?? {}) as MedicalInputData;

    // replace undefined values with zero number to have all keys in answer value
    // keys are terms from the HL7 EventTiming code system
    // https://www.hl7.org/fhir/codesystem-event-timing.html#4.3.14.178.2
    // setValue(name, mapValues(values, parseValue));
    // loop over TimeCode
    const startObject = {} as Record<TimeCode, number>;
    const result = Object.keys(TimeCode).reduce<typeof startObject>(
      (acc, key) => {
        const code = key as TimeCode;
        const valid = Object.values(TimeCode).includes(code);
        if (!valid) return acc;
        const timeCodeValue = value[code] ?? 0;
        return {
          ...acc,
          [key]: parseFloat(`${timeCodeValue}`)
        };
      },
      startObject
    );
    return result;
  };

  static parseFieldValueDropdown = (
    field: QuestionnaireField,
    formData: CachedObject
  ): CustomQuestionnaireChoice => {
    const fieldChoices = field.properties?.choices ?? [];
    const fieldValue = fieldChoices.find((choice) => formData[choice.id]);

    if (!fieldValue) {
      return { choiceId: "", value: "" };
    }

    const value: CustomQuestionnaireChoice = {
      choiceId: fieldValue.id,
      value: fieldValue.label
    };

    return value;
  };

  static parseFieldValueMultipleChoice = (
    field: QuestionnaireField,
    formData: CachedObject
  ): CustomQuestionnaireChoice[] => {
    const fieldChoices = field.properties?.choices ?? [];
    return fieldChoices
      .map((choice) => {
        const selected = formData[choice.id];
        const value = selected
          ? ({
              choiceId: choice.id,
              value: choice.label
            } as CustomQuestionnaireChoice)
          : false;

        // remove comments from option
        if (value && typeof value.value === "string") {
          value.value = removeQuestionnaireKeywordsFromText(value.value, [
            CustomFieldPropertyRegex.GROUP_COMMENT,
            CustomFieldPropertyRegex.CLEAR_OTHER,
            CustomFieldPropertyRegex.AFFIX_SUFFIX
          ]);
        }
        return value;
      })
      .filter(Boolean);
  };

  static parseFieldValueNumber = (
    field: QuestionnaireField,
    formData: CachedObject
  ): number => {
    return parseFloat(String(formData[field.id]));
  };

  static parseFieldValueBoolean = (
    field: QuestionnaireField,
    formData: CachedObject
  ): boolean => {
    const value = formData[field.id];

    if (QuestionnaireType.YES_NO === field.type) {
      return String(value).toLowerCase() === "yes";
    }

    return false;
  };

  readonly replacePlaceholders = (
    inputText?: string,
    joinListBy?: string
  ): string | undefined => {
    if (!inputText) return undefined;
    const { removeFieldPropertiesKeywords } = QuestionnaireStepCubit;
    const values = { ...this.customFormVariables, ...this.state.hiddenFields };
    let text = inputText;
    const varRegex = /\{\{(hidden|var):(.*?)\}\}/g;
    text = text.replace(varRegex, (_match, _type, key: string): string => {
      const unescaped = key.replace(/\\/g, "");
      const value = values[unescaped];

      // TODO: Consider how to handle in more proper way for object value
      if (value instanceof Object) return "";

      return value ? removeFieldPropertiesKeywords(`${value}`) : "";
    });

    const fieldRegex = /\{\{(field):(.*?)\}\}/g;
    text = text.replace(fieldRegex, (_match, _type, ref: string): string => {
      const unescaped = ref.replace(/\\/g, "");
      const { value } = this.parseAnswerOnlyValue(unescaped, { joinListBy });

      // TODO: Consider how to handle in more proper way for object value
      if (value instanceof Object) return "";

      return removeFieldPropertiesKeywords(`${value ?? ""}`);
    });

    return text;
  };

  readonly insertPlaceholders = (
    field: QuestionnaireField
  ): QuestionnaireField => {
    if (field.originalTitle) {
      field.title = this.replacePlaceholders(field.originalTitle, ", ") ?? "";
    }

    if (field.properties) {
      // description
      field.properties.description = this.replacePlaceholders(
        field.properties.originalDescription
      );

      // button text
      field.properties.button_text = this.replacePlaceholders(
        field.properties.button_text
      );

      // button link
      field.properties.button_link = this.replacePlaceholders(
        field.properties.button_link
      );
    }

    return QuestionnaireStepCubit.parseField(field);
  };

  /***************************************************************************
   *
   * Questionnaire Logic
   *
   ***************************************************************************/
  readonly resolveLogicInnerVars = (
    vars: QuestionnaireLogicConditionVarInner[],
    fields: QuestionnaireField[]
  ): QuestionnaireLogicConditionVarInnerResolved => {
    let field: QuestionnaireField | undefined;
    let hiddenFieldValue: QuestionnaireValue | undefined;
    let choice: QuestionnaireSelectChoice | undefined;
    const comparandValue: QuestionnaireValue[] = [];
    const subjectValue: QuestionnaireValue[] = [];
    let match = false;

    for (const v of vars) {
      // if type field, we get the field that matches the value
      if (v.type === "field") {
        field = fields.find((f) => f.ref === v.value);
      }

      if (v.type === "hidden") {
        hiddenFieldValue =
          this.customFormVariables[String(v.value)] ??
          this.state.hiddenFields[String(v.value)];
        comparandValue.push(hiddenFieldValue);
      }
    }

    for (const v of vars) {
      switch (v.type) {
        case "choice":
          choice = field?.properties?.choices?.find(
            (c) => c.ref === v.value || c.id === v.value
          );
          if (choice) {
            subjectValue.push(choice.id);
          }

          if (field) {
            field.properties?.choices?.forEach((c) => {
              const value = this.getValue(c.id)?.[0];
              if (value && c.id) {
                comparandValue.push(c.id);
              }
            });
          }

          match = Boolean(comparandValue.includes(subjectValue[0]));
          break;
        case "field":
        case "hidden":
          // these cases is handled above and can be ignored here
          break;
        case "variable":
          comparandValue.push(this.customFormVariables[String(v.value)]);
          match = Boolean(subjectValue.includes(comparandValue[0]));
          break;
        case "constant":
          if (field) {
            this.getValue(field.id)?.forEach((value) => {
              if (String(value).toLowerCase() === "yes") {
                comparandValue.push(true);
              } else if (String(value).toLowerCase() === "no") {
                comparandValue.push(false);
              } else {
                comparandValue.push(value);
              }
            });
          }

          match = Boolean(comparandValue.includes(v.value));

          subjectValue.push(v.value);
          break;
      }
    }

    return {
      field,
      comparandValue,
      match,
      subjectValue
    };
  };

  readonly evaluateLogicConditionVar = (
    check: QuestionnaireLogicCondition,
    fields: QuestionnaireField[]
  ): boolean => {
    let pass = true;
    // eslint-disable-next-line no-prototype-builtins
    const isFlatCondition = !check.vars.some((c) => c.hasOwnProperty("op"));

    if (isFlatCondition) {
      const { vars } = check;
      const { match, subjectValue, comparandValue } =
        this.resolveLogicInnerVars(
          vars as QuestionnaireLogicConditionVarInner[],
          fields
        );

      switch (check.op) {
        case "is_not":
        case "not_equal":
          pass = !match;
          break;
        case "is":
        case "equal":
          pass = match;
          break;
        case "always":
          pass = true;
          break;
        case "begins_with":
          // Assumes single values for the comparand and the subject
          pass = String(comparandValue[0]).startsWith(String(subjectValue[0]));
          break;
        case "contains":
          pass = String(comparandValue[0]).includes(String(subjectValue[0]));
          break;
        case "not_contains":
          pass = !String(comparandValue[0]).includes(String(subjectValue[0]));
          break;
        case "lower_than":
          // Assumes single values for the comparand and the subject
          pass = Number(subjectValue[0]) > Number(comparandValue[0]);
          break;
        case "lower_equal_than":
          // Assumes single values for the comparand and the subject
          pass = Number(subjectValue[0]) >= Number(comparandValue[0]);
          break;
        case "greater_than":
          // Assumes single values for the comparand and the subject
          pass = Number(subjectValue[0]) < Number(comparandValue[0]);
          break;
        case "greater_equal_than":
          // Assumes single values for the comparand and the subject
          pass = Number(subjectValue[0]) <= Number(comparandValue[0]);
          break;
        default:
          reportErrorSentry(
            `Not implemented yet: ${check.op} case for "evaluateLogicConditionVar"`
          );
      }

      return pass;
    }

    const conditionState = this.runLogicConditions(check, fields);
    return this.runConditionLogic(check, conditionState);
  };

  readonly runLogicConditions = (
    condition: QuestionnaireLogicCondition,
    fields: QuestionnaireField[]
  ): boolean[] => {
    // eslint-disable-next-line no-prototype-builtins
    const hasFlatCondition = condition.vars.some((c) => c.hasOwnProperty("op"));

    const conditionState: boolean[] = hasFlatCondition
      ? condition.vars.map((check) => {
          return this.evaluateLogicConditionVar(
            check as QuestionnaireLogicCondition,
            fields
          );
        })
      : [this.evaluateLogicConditionVar(condition, fields)];

    return conditionState;
  };

  readonly evaluateLogicActionConditions = (
    action: QuestionnaireLogicAction,
    fields: QuestionnaireField[]
  ): boolean => {
    const { condition } = action;
    const conditionState = this.runLogicConditions(condition, fields);
    return this.runConditionLogic(condition, conditionState);
  };

  readonly runConditionLogic = (
    condition: QuestionnaireLogicCondition,
    conditionState: boolean[]
  ): boolean => {
    let pass = false;
    switch (condition.op) {
      case "and": // and is for conditions with multiple checks
      case "is": // is is for conditions with a single check
      case "equal": // equal is a inner var condition and will be checked in runLogicConditions
      case "greater_than": // greater_than is a inner var condition and will be checked in runLogicConditions
      case "lower_than": // greater_than is a inner var condition and will be checked in runLogicConditions
      case "is_not": // can be treated same as "is", evaluateLogicConditionVar flips the result if op is "is_not"
      case "greater_equal_than": // same as "greater_than" but includes equal
      case "not_contains":
      case "contains":
      case "lower_equal_than": // same as "lower_than" but includes equal
        pass = conditionState.every(Boolean);
        break;
      case "or":
        pass = conditionState.some(Boolean);
        break;
      case "always":
        pass = true;
        break;
      default:
        reportErrorSentry(
          `Not implemented yet: ${condition.op} case for "runConditionLogic"`
        );
    }

    return pass;
  };

  readonly runLogicActions = (
    action: QuestionnaireLogicAction,
    options: {
      jumpActionFound?: boolean;
      auto?: boolean;
    } = {}
  ): { jumpActionFound: boolean } => {
    const { fields = [] } = this.state;
    const runAction = this.evaluateLogicActionConditions(action, fields);

    if (runAction) {
      switch (action.action) {
        case "jump":
          if (!options.jumpActionFound) {
            if (options.auto) {
              this.preventedJump =
                action.details as QuestionnaireLogicDetailJump;
            } else {
              this.handleJump(action.details as QuestionnaireLogicDetailJump);
            }
          }
          return { jumpActionFound: true };

        case "set":
          this.handleActionSet(action.details as QuestionnaireLogicDetailSet);
          return { jumpActionFound: false };

        case "add":
          if (!this.dryRun) {
            this.handleActionCalc(
              action.details as QuestionnaireLogicDetailSet,
              (a, b) => a + b
            );
          }
          return { jumpActionFound: false };

        case "multiply":
          if (!this.dryRun) {
            this.handleActionCalc(
              action.details as QuestionnaireLogicDetailSet,
              (a, b) => a * b
            );
          }
          return { jumpActionFound: false };

        case "divide":
          if (!this.dryRun) {
            this.handleActionCalc(
              action.details as QuestionnaireLogicDetailSet,
              (a, b) => a / b
            );
          }

          return { jumpActionFound: false };

        default:
          reportErrorSentry(
            `Unknown action ${String(action.action)} in "runLogicForField"`
          );
      }
    }
    return { jumpActionFound: false };
  };

  readonly runLogicForField = (
    field: QuestionnaireField,
    options: { auto?: boolean } = {}
  ): boolean => {
    const fieldRef = field.ref;
    const { logic = [] } = this.state;
    let jumpActionFound = false;

    const fieldLogic = logic.find((l) => l.ref === fieldRef);
    this.preventedJump = undefined;

    if (fieldLogic) {
      for (const action of fieldLogic.actions) {
        const result = this.runLogicActions(action, {
          jumpActionFound,
          auto: options.auto
        });
        if (result.jumpActionFound) {
          jumpActionFound = true;
        }
      }
    }

    if (!jumpActionFound) {
      const nextField = this.getNextField(field);

      if (nextField) {
        this.setActiveStep({
          to: {
            type: "field",
            value: nextField.ref
          }
        });
      }
    }

    return false;
  };

  readonly getNextField = (
    field: QuestionnaireField
  ): QuestionnaireField | undefined => {
    let nextIndex = 0;
    const { fields, endScreens } = this.state;
    const all = [...fields, ...endScreens];

    for (const f of all) {
      nextIndex++;
      if (f.id === field.id) {
        break;
      }
    }
    return all[nextIndex] as QuestionnaireField;
  };

  readonly handleActionCalc = (
    details: QuestionnaireLogicDetailSet,
    method: (a: number, b: number) => number
  ): void => {
    const key = details.target.value;
    const valueVar = this.customFormVariables[key];
    const currentValue = typeof valueVar === "number" ? valueVar : 0;

    const valueType = details.value.type;

    if (valueType === "field") {
      const value = this.parseAnswerOnlyValue(details.value.value);
      this.customFormVariables[key] = method(
        currentValue,
        parseFloat(String(value.value))
      );
    } else if (valueType === "constant") {
      const { value } = details.value;
      this.customFormVariables[key] = method(currentValue, parseFloat(value));
    } else if (valueType === "variable") {
      const varValue = this.customFormVariables[details.value.value];
      const value = typeof varValue === "number" ? varValue : 0;
      this.customFormVariables[key] = method(
        currentValue,
        parseFloat(`${value}`)
      );
    }
  };

  readonly handleActionSet = (details: QuestionnaireLogicDetailSet): void => {
    if (this.dryRun) return;

    const targetType = details.target.type;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (targetType === "variable") {
      const key = details.target.value;
      const { value } = details.value;
      this.customFormVariables[key] = value;
    } else {
      reportErrorSentry(`Unknown target type ${String(targetType)}`);
    }
  };

  readonly setMultiStepBloc = (multiStepBloc?: MultiStepFormCubit): void => {
    this.multiStepBloc = multiStepBloc;
  };

  readonly handleJump = (
    details: QuestionnaireLogicDetailJump,
    replace = false
  ): void => {
    this.activeField = this.state.fields.find(
      (f) => f.ref === details.to.value
    );
    this.setActiveStep(details, replace);
  };

  readonly setActiveStep = (
    details: QuestionnaireLogicDetailJump,
    replace = false
  ): void => {
    this.activeField = this.state.fields.find(
      (f) => f.ref === details.to.value
    );
    if (this.dryRun) {
      this.dryRunSteps.push(details.to.value);
    } else {
      this.multiStepBloc?.setActiveStepByName(details.to.value, { replace });
    }
  };

  private readonly canSkipField = (field: QuestionnaireField): boolean => {
    const isValid = this.runValidations(field).length === 0;
    const hasValue = this.parseAnswer(field) !== undefined;
    return isValid && hasValue;
  };

  readonly collectLogicSteps = (
    options: {
      addStepsToState?: boolean;
    } = {}
  ): void => {
    this.dryRun = true;
    const start = this.activeField && { ...this.activeField };
    this.dryRunSteps = [];
    const { fields = [], endScreens = [] } = this.state;

    let currentStep = fields[0] as QuestionnaireField | undefined;
    if (!currentStep) {
      this.dryRun = false;
      this.activeField = start;
      return;
    }

    this.dryRunSteps.push(currentStep.ref);

    this.runLogicForField(currentStep);
    let nextStep = this.getFieldByRef(
      this.dryRunSteps[this.dryRunSteps.length - 1]
    );

    let tries = 0;

    while (nextStep && !this.isFinishStep(nextStep)) {
      currentStep = nextStep;

      this.runLogicForField(currentStep);

      nextStep = this.getFieldByRef(
        this.dryRunSteps[this.dryRunSteps.length - 1]
      );

      tries++;
      if (tries > fields.length + endScreens.length) {
        break;
      }
    }

    this.dryRun = false;
    this.activeField = start;

    if (options.addStepsToState) {
      this.emit({
        ...this.state,
        logicSteps: this.dryRunSteps
      });
    }
  };

  customKeyPrefix = "custom_";
  addToKeyList = (key: string): void => {
    this.customKeyList.add(key);
    this.customFormVariables[`${this.customKeyPrefix}${key}`] = "true";
    this.collectLogicSteps();
  };

  removeFromKeyList = (key: string): void => {
    this.customKeyList.delete(key);
    this.customFormVariables[`${this.customKeyPrefix}${key}`] = "false";
    this.collectLogicSteps();
  };

  checkIfKeyExists = (key: string): boolean => {
    return this.customKeyList.has(key);
  };

  /**
   * Session management
   **/
  currentSessionId?: string;
  sessionStarted = false;

  startSession = async () => {
    if (userState.isTempUser) return;
    if (this.sessionStarted) return;

    this.sessionStarted = true;
    const questionnaireId = this.state.formId;
    if (!questionnaireId) return;
    try {
      document.dispatchEvent(
        new CustomEvent("nineQuestionnaireStarted", {
          bubbles: true,
          composed: true,
          detail: { ...this.state }
        })
      );

      const sessionRequest = QuestionnaireControllerService.startSession({
        id: questionnaireId,
        type: StartSessionRequest.type.TYPEFORM
      });
      const session = await sessionRequest;

      this.currentSessionId = session.data.sessionId;
    } catch (e) {
      this.sessionStarted = false;
      reportErrorSentry(e);
    }
  };

  addAnswerToSession = async (questionId: string) => {
    if (!this.sessionStarted) return;
    if (!this.currentSessionId) {
      addSentryBreadcrumb(
        "api",
        `Session not started when adding answer, creating new one`
      );
      await this.startSession();
    }

    try {
      if (!this.currentSessionId) {
        throw new Error("Session not started");
      }

      const fullParsedAnswer = this.parseAnswer(questionId);

      if (!fullParsedAnswer?.fieldValue) {
        return;
      }

      const answer = {
        questionId,
        answer: fullParsedAnswer.fieldValue
      } satisfies AddAnswerToSessionRequest;

      await QuestionnaireControllerService.addAnswerToSession(
        this.currentSessionId,
        answer
      );
    } catch (e) {
      reportErrorSentry(e);
    }
  };
}
