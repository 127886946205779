import type { EventTrackingRequest } from "@9amhealth/openapi";
import { AnalyticsControllerService } from "@9amhealth/openapi";
import * as Sentry from "@sentry/react";
import { Cubit } from "blac";
import type { RequestOptions } from "mixpanel-browser";
import mixpanel from "mixpanel-browser";
import type { Location } from "react-router-dom";
import { globalEvents } from "src/constants/globalEvents";
import typeformForms from "src/constants/typeformForms";
import { PLATFORM } from "src/hybrid/components/Platform";
import { addSentryBreadcrumb } from "src/lib/addSentryBreadcrumb";
import createTrackEvent from "src/lib/createTrackEvent";
import envVariables from "src/lib/envVariables";
import { FeatureFlagName, featureFlags } from "src/lib/featureFlags";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { ProfileAttributesKeys } from "src/state/UserCubit/UserCubit";
import {
  appVersionState,
  authenticationState,
  userState
} from "src/state/state";

import { _ as mixpanelUtils } from "mixpanel-browser/src/utils";

interface TrackData {
  trackingId: string;
}

export enum TrackType {
  start = "Started",
  complete = "Completed",
  cancel = "Cancelled",
  present = "Presented",
  select = "Selected",
  click = "Clicked",
  fail = "Failed",
  open = "Opened",
  close = "Closed",
  authenticated = "Authenticated",
  view = "View",
  changed = "Changed"
}

export function setupMixpanel() {
  mixpanel.init(envVariables.MIXPANEL_TOKEN, {
    cross_subdomain_cookie: true,
    cross_site_cookie: true,
    verbose:
      envVariables.DEBUG &&
      featureFlags.getFlag(FeatureFlagName.verboseLogging),
    debug:
      envVariables.DEBUG && featureFlags.getFlag(FeatureFlagName.verboseLogging)
  });

  if (typeof window !== "undefined") {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
    (window as any).mixpanel = mixpanel;
  }
}

export enum TrackEvent {
  registration = "Registration",
  login = "Login",
  logout = "Logout",
  messageSent = "Message Sent",
  ineligible = "Ineligible",
  app = "App",
  messenger = "Messenger",
  myPlan = "My Plan",
  profile = "Profile Page",
  referral = "Referral",
  labValues = "Lab Values",
  subscription = "Subscription",
  subInstructions = "Subscription Instructions",
  subCustomization = "Subscription Customization",
  subFrequency = "Subscription Frequency",
  subShippingForm = "Subscription Shipping Information",
  subVerifyFace = "Subscription Verification Photo Face",
  subVerifyDob = "Subscription Verification DOB",
  subVerifyDocument = "Subscription Verification Photo ID",
  subReviewAndPayment = "Subscription Order Review and Payment Authorization",
  subSms = "Subscription SMS notifications",
  readMore = "Read More",
  changeEmail = "Change Email",
  changePassword = "Change Password",
  changeShippingAddress = "Change Shipping Address",
  changeNumber = "Change Number",
  video = "Video",
  couponApplied = "Discount",
  setCouponCode = "Set Coupon Code",
  questionnaire_step = "Questionnaire Question",
  questionnaire = "Questionnaire",
  appStart = "App Start",
  OrderFunnel = "Order Funnel",
  ButtonClick = "Button Click",
  PromoRegisterPage = "Promo Register Page",
  cart = "Cart",
  TEST_STRIPS_FUNNEL = "Test Strip Funnel",
  TEST_STRIPS_CUSTOMIZATION = "Test Strip Customization",
  TEST_STRIPS_QUANTITY = "Test Strip Quantity",
  TEST_STRIPS_LANCETS = "Test Strip Lancets",
  TEST_STRIPS_ORDER_REVIEW_PAYMENT = "Test Strip Order Review and Payment",
  TEST_STRIPS_FREQUENCY_CUSTOMIZATION = "Test Strip Frequency Customization",
  REQUEST_CHANGES = "Request Changes",
  REMOVE_MEDICATION_SHEET = "Remove Medication Sheet",
  REMOVE_MEDICATION_SHEET_MULTIPLE_MEDS = "Remove Medication Sheet Multiple Meds",
  BloodPressureForm = "Blood Pressure Form",
  BloodGlucoseForm = "Blood Glucose Form",
  WeightForm = "Weight Form",
  WaistCircumferenceForm = "Waist Circumference Form",
  BioSetCredentials = "Biometric Set Credentials",
  BioVerifyIdentity = "Biometric Verify Identity",
  BioGetCredentials = "Biometric Get Credentials",
  ExperienceRated = "Experience rated",
  LabReportOpened = "Lab Report Opened",
  LabTestToggleOpened = "Lab Test Toggle Opened",
  UserEnrolledInJourney = "User enrolled in Journey",
  mealPlanningOpened = "Meal planning opened",
  AppointmentJoinCallClick = "Appointment Join Call Click",
  AppointmentAddToCalClick = "Appointment Add To Cal Click",
  AppointmentItemClicked = "Appointment Item Clicked"
}

function getQueryParam(url: string, param: string): string {
  const re = new RegExp(`(\\?|&)${param}=([^&]*)`);
  const match = re.exec(url);
  return match?.[2] ?? "";
}

type Dict = Record<string, string[] | boolean | number | string | undefined>;

export interface TrackConfig {
  data?: Dict;
  type?: TrackType;
  endType?: TrackType;
  backend?: boolean; // use backend for event tracking
}

window.dataLayer = window.dataLayer ?? [];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function gtag(...args: any): void {
  window.dataLayer?.push(args);
}
window.gtag = gtag;

gtag("set", "linker", { domains: ["join9am.com", "9am.health"] });

export default class TrackCubit extends Cubit<TrackData> {
  timers: Record<string, number> = {};
  utmParams: Dict = {};

  constructor() {
    super({ trackingId: "" });
    this.setUtmParams();
    this.addVisibilityListener();
    if (envVariables.PROD) {
      this.loadGTag();
    }

    window.addEventListener(globalEvents.USER_CLEAR, () => {
      this.emit({ trackingId: "" });
      this.resetMixpanelUser();
    });
  }

  private readonly log = (message: string, etc?: unknown): void => {
    if (featureFlags.getFlag(FeatureFlagName.loggingTrackingEvents)) {
      // eslint-disable-next-line no-console
      console.info(`[TRACK]: ${message}`, etc ?? "");
    }
  };

  loadedGTag = false;
  private readonly loadGTag = (): void => {
    if (!this.loadedGTag) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ((w: any, d, s, l, i) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        w[l] = w[l] || [];
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        const f = d.getElementsByTagName(s)[0] as HTMLScriptElement | undefined,
          j = d.createElement(s) as HTMLScriptElement,
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f?.parentNode?.insertBefore(j, f);
        this.loadedGTag = true;
      })(window, document, "script", "dataLayer", "GTM-P8XC69X");
    }
  };

  private readonly setUtmParams = (): void => {
    const campaignKeywords =
      "utm_source utm_medium utm_campaign utm_content utm_term".split(" ");
    const location = window.location.href;
    const firstTouch: Dict = {};

    for (const key of campaignKeywords) {
      const value = getQueryParam(location, key);
      if (value) {
        this.utmParams[`${key} [last touch]`] = value;
        firstTouch[`${key} [first touch]`] = value;
      }
    }

    // Set first touch UTM params
    mixpanel.people.set_once(firstTouch);

    // Set last touch UTM params
    mixpanel.people.set(this.utmParams);
  };

  public readonly setCustomUserAttribute = (
    key: string,
    value: string[] | boolean | number | string | null
  ): void => {
    this.log(`custom user attribute set: ${key}=${value?.toString()}`);
    if (envVariables.DEBUG) {
      return;
    }

    mixpanel.people.set({ [key]: value });
  };

  public readonly setCustomUserAttributes = (props: Dict): void => {
    // keys can come in in this format: user.signup-funnel-status.weight-loss, we should make it more readable
    const newProps: Dict = {};
    for (const key in props) {
      const newKey = key
        .replace("user.", "")
        .replace(/-/g, " ")
        .replace(/\./g, " - ");
      newProps[newKey] = props[key];
    }

    this.log(`custom user attributes set:`, newProps);

    mixpanel.people.set(newProps);
  };

  trackPageView = (location: Location): void => {
    const page = location.pathname + location.search;
    this.track("App Page View", {
      data: {
        page,
        title: document.title
      }
    });
  };

  cachedTrackingId = "";
  public readonly identifyUserByTrackingId = (trackingId: string): void => {
    const useId = trackingId || this.cachedTrackingId;
    if (!useId) return;
    this.log(`IDENTIFY: ${useId}`);
    Sentry.setUser({ id: useId });
    mixpanel.identify(useId);
    this.cachedTrackingId = useId;

    this.track("User Identified - Save Browser", {
      backend: false
    });

    if (typeof window !== "undefined" && window.parent !== window) {
      window.parent.postMessage(
        { analyticsToken: trackingId },
        "https://join9am.com"
      );
    }
  };

  public readonly resetMixpanelUser = (): void => {
    this.log("MIXPANEL RESET");
    mixpanel.reset();
  };

  prevAlias = "";
  public readonly setAlias = (alias: string): void => {
    if (!alias || this.prevAlias === alias) {
      return;
    }
    this.prevAlias = alias;
    this.log(`ALIAS SET: ${alias}`);
    if (envVariables.DEBUG) {
      return;
    }

    mixpanel.alias(alias);

    Sentry.setUser({ id: alias });
  };

  public readonly track = (
    eventName: TrackEvent | string,
    config: TrackConfig = {}
  ): void => {
    const userIsAuthenticated = Boolean(authenticationState.accessToken);
    const {
      type = "",
      data = {},
      backend = userIsAuthenticated ? true : false
    } = config;
    const fullName = eventName + (type ? ` ${type}` : "");

    // Add duration to event
    if (type === TrackType.start || type === TrackType.open) {
      // Log when the event started
      this.timers[eventName] = Date.now();
    }

    if (
      (type === TrackType.complete ||
        type === TrackType.cancel ||
        type === TrackType.close) &&
      this.timers[eventName]
    ) {
      // end the timer, add $duration to the properties
      const duration = new Date().getTime() - this.timers[eventName];
      data.$duration = parseFloat((duration / 1000).toFixed(3));
    }

    this.submitTrackingEvent(fullName, data, backend);
    addSentryBreadcrumb("tracker", fullName, "log");
  };

  public readonly startTimer = (eventName: TrackEvent): void => {
    this.timers[eventName] = Date.now();
  };

  public readonly trackForm = (formId: string, type: TrackType): void => {
    const formName = typeformForms[formId] || `Unknown Form: "${formId}"`;

    this.track(createTrackEvent(formName), {
      type
    });
  };

  public readonly submitTrackingEvent = (
    name: string,
    initialData: Dict,
    backend: boolean
  ): void => {
    let data = { ...initialData };
    // Add payer, employer, program attributes to all events where available
    const { profileAttributes } = userState.state;
    const payer =
      profileAttributes?.[ProfileAttributesKeys.partnerPayersCurrent];
    const employer =
      profileAttributes?.[ProfileAttributesKeys.partnerEmployersCurrent];
    const programMemberships =
      profileAttributes?.[ProfileAttributesKeys.programMemberships];
    const activeProgram = programMemberships?.find(
      (program) => program.active || (!program.start && !program.end)
    );

    if (payer?.active) {
      data.Payer = payer.partner.name;
    }
    if (employer?.active) {
      data.Employer = employer.partner.name;
    }
    if (activeProgram) {
      data.Program = activeProgram.program;
    }

    data.BUILD_VERSION = appVersionState.currentVersionString;
    data.BUILD_PLATFORM = envVariables.APP_ENV;
    data.PLATFORM = PLATFORM;
    data.APPLICATION_ID = envVariables.APPLICATION_ID;

    try {
      const mixpanelEventProps = mixpanelUtils.info.properties();
      if (mixpanelEventProps) {
        data = {
          ...data,
          "Operating System": mixpanelEventProps.$os,
          Browser: mixpanelEventProps.$browser,
          "Browser Version": mixpanelEventProps.$browser_version,
          "Screen Height": mixpanelEventProps.$screen_height,
          "Screen Width": mixpanelEventProps.$screen_width,
        };
      }
    } catch (error) {
      reportErrorSentry(error);
    }

    this.log(`[${backend ? "BACKEND" : "MIXPANEL"}] TRACK: ${name}`, {
      data,
      useBackend: backend
    });

    if (envVariables.DEBUG) {
      return;
    }

    const options: RequestOptions = {};
    if (backend) {
      const requestBody: EventTrackingRequest = {
        eventName: name,
        properties: data
      };

      void AnalyticsControllerService.trackEvent(requestBody);
    } else {
      mixpanel.track(name, data, options);
    }

    if (typeof window.dataLayer !== "undefined") {
      window.dataLayer.push({ event: name });
    } else {
      reportErrorSentry('"dataLayer" is undefined');
    }

    try {
      // only continue if are on production environment
      if (envVariables.APP_ENV !== "production") return;
      if (typeof window.ga !== "undefined") {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access,@typescript-eslint/prefer-destructuring, @typescript-eslint/no-unsafe-call
        const gtrack = window.ga.getAll()[0];
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        if (gtrack) gtrack.send("event", "App", name);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  private readonly addVisibilityListener = (): void => {
    let tmpTime = 0;
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        if (tmpTime !== 0) {
          const timeAway = Date.now() - tmpTime;
          for (const key in this.timers) {
            // add the time away to the "start" time, to subtract it from the total
            this.timers[key] += timeAway;
          }

          tmpTime = 0;
        }
      } else {
        tmpTime = Date.now();
      }
    });

    window.addEventListener("pageshow", () => {
      if (tmpTime !== 0) {
        const timeAway = Date.now() - tmpTime;
        for (const key in this.timers) {
          // add the time away to the "start" time, to subtract it from the total
          this.timers[key] += timeAway;
        }

        tmpTime = 0;
      }
    });
    window.addEventListener("pagehide", () => {
      tmpTime = Date.now();
    });
  };
}
