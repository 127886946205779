import { CapacitorUpdater } from "@capgo/capacitor-updater";
import type {
  BundleInfo,
  BundleListResult,
  LatestVersion
} from "@capgo/capacitor-updater";
import { App } from "@capacitor/app";
import { Cubit } from "blac";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { isHybridApp } from "src/lib/platform";
import { tracker } from "src/state/state";
import { TrackEvent } from "src/state/Track/TrackCubit";
import envVariables from "src/lib/envVariables";
import translate from "src/lib/translate";
import { ActionSheet, ActionSheetButtonStyle } from "@capacitor/action-sheet";

export type AppVersionState = {
  current?: {
    bundle: BundleInfo;
  };
  native: string;
  local?: BundleListResult;
  latest?: LatestVersion;
  pluginDetails?: unknown;
};

export type AppUpdateData = {
  updateAvailable: boolean;
  currentVersion?: string | undefined;
  latestVersion?: string | undefined;
  latestBundle?: BundleInfo | undefined;
};

export default class AppVersionBloc extends Cubit<AppVersionState> {
  enabled = isHybridApp();

  constructor() {
    super({
      native: envVariables.BUILD_VERSION
    });
    this.init();
  }

  init = () => {
    if (!this.enabled) {
      return;
    }
    void CapacitorUpdater.notifyAppReady();
    this.addAppListeners();
    void this.checkPluginConfig();
    void this.checkLatestVersion();
    void this.checkLocalVersions();
    void this.checkCurrentVersion();
  };

  checkPluginConfig = async () => {
    try {
      const pluginDetails = {
        latest: await CapacitorUpdater.getLatest(),
        channel: await CapacitorUpdater.getChannel(),
        getPluginVersion: await CapacitorUpdater.getPluginVersion()
      };
      this.emit({ ...this.state, pluginDetails });
    } catch (err) {
      reportErrorSentry(err);
    }
  };

  addAppListeners = () => {
    void App.addListener("appStateChange", (data) => {
      if (data.isActive) {
        void this.loadVersionData();
      }
    });
    void CapacitorUpdater.addListener("downloadComplete", (data) => {
      tracker.track("OTA Update Downloaded" as TrackEvent, {
        data: {
          current: this.state.current?.bundle.version,
          version: data.bundle.version
        }
      });
    });
    void CapacitorUpdater.addListener("updateAvailable", (data) => {
      tracker.track("OTA Update Available" as TrackEvent, {
        data: {
          current: this.state.current?.bundle.version,
          version: data.bundle.version
        }
      });
    });
    void CapacitorUpdater.addListener("updateFailed", (data) => {
      reportErrorSentry(new Error("OTA Update Failed"), {
        updateVersion: data.bundle.version,
        updateId: data.bundle.id
      });
    });
  };

  loadVersionData = async () => {
    if (!this.enabled) {
      return;
    }
    await this.checkCurrentVersion();
    await this.checkForUpdate();
  };

  setVersionAfterRestart = async (bundle?: BundleInfo) => {
    if (!bundle) {
      return;
    }

    try {
      await CapacitorUpdater.next(bundle);
    } catch (err) {
      reportErrorSentry(err);
    }
  };

  setVersion = async (bundle?: BundleInfo) => {
    if (!bundle) {
      return;
    }

    try {
      await CapacitorUpdater.set(bundle);
    } catch (err) {
      reportErrorSentry(err);
    }
  };

  checkCurrentVersion = async () => {
    try {
      const current = await CapacitorUpdater.current();
      this.emit({ ...this.state, current });
      this.attemptAppUpdate();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (_err) {
      // empty
    }
  };

  checkLatestVersion = async (): Promise<LatestVersion | undefined> => {
    try {
      const latest = await CapacitorUpdater.getLatest();
      this.emit({ ...this.state, latest });
      this.attemptAppUpdate();
      return latest;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.info(err);
    }
    return undefined;
  };

  checkLocalVersions = async (): Promise<BundleListResult> => {
    try {
      const local = await CapacitorUpdater.list();
      this.emit({ ...this.state, local });
      this.attemptAppUpdate();
      return local;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.info(err);
    }
    return { bundles: [] };
  };

  checkForUpdate = async () => {
    try {
      const latest = await this.checkLatestVersion();
      const list = await this.checkLocalVersions();

      if (!latest) {
        return;
      }

      const latestDownloaded = list.bundles.find(
        (b) => b.version === latest.version
      );

      if (latestDownloaded) {
        tracker.track("OTA Update in cache" as TrackEvent, {
          data: {
            current: this.state.current?.bundle.version,
            ...latestDownloaded
          }
        });
        return;
      }

      if (latest.url) {
        const data = await CapacitorUpdater.download({
          url: latest.url,
          version: latest.version
        });
        tracker.track("OTA Update Downloaded" as TrackEvent, {
          data: {
            current: this.state.current?.bundle.version,
            ...data
          }
        });
        await this.checkLocalVersions();
      }
    } catch (err) {
      reportErrorSentry(err);
    }
  };

  get currentVersionString(): string {
    const web = !isHybridApp();

    if (web) {
      return envVariables.BUILD_VERSION;
    }

    const { bundle } = this.state.current ?? {};
    const bundleVersion = bundle?.version ?? "";
    const { native } = this.state;

    if (!bundleVersion) {
      return native;
    }

    return `${bundleVersion} (${native})`;
  }

  reset = () => {
    CapacitorUpdater.reset()
      .then(() => {})
      // eslint-disable-next-line no-console, @typescript-eslint/use-unknown-in-catch-callback-variable
      .catch(console.error);
  };

  attemptAppUpdate = () => {
    const updateData = this.collectNewVersionData();

    if (updateData.updateAvailable && updateData.latestBundle) {
      void this.setVersionAfterRestart(updateData.latestBundle);
    }
  };

  collectNewVersionData = (): AppUpdateData => {
    const { current, latest, local } = this.state;
    const collectedData: AppUpdateData = {
      currentVersion: current?.bundle.version,
      latestVersion: latest?.version,
      latestBundle: local?.bundles.find((b) => b.version === latest?.version),
      updateAvailable: false
    };

    collectedData.updateAvailable = Boolean(
      collectedData.currentVersion &&
        collectedData.latestVersion &&
        collectedData.latestBundle &&
        collectedData.currentVersion !== collectedData.latestVersion
    );

    return collectedData;
  };

  promptUpdateDialog = async (options: AppUpdateData) => {
    const { currentVersion, latestVersion, latestBundle, updateAvailable } =
      options;

    if (!updateAvailable || this.promptedNewVersion) {
      return;
    }

    this.promptedNewVersion = true;
    try {
      const result = await ActionSheet.showActions({
        title: translate("appUpdateTitle"),
        message: translate("newVersionAvailable"),
        options: [
          {
            title: translate("update"),
            style: ActionSheetButtonStyle.Default
          },
          {
            title: translate("later"),
            style: ActionSheetButtonStyle.Cancel
          }
        ]
      });
      const updateNow = result.index === 0;

      tracker.track("OTA Update Prompted" as TrackEvent, {
        data: {
          current: currentVersion,
          latest: latestVersion,
          userAction: updateNow ? "update" : "later"
        }
      });

      if (updateNow) {
        void this.setVersion(latestBundle);
      }
    } catch (error) {
      reportErrorSentry(error);
    }
    this.promptedNewVersion = false;
  };
  promptedNewVersion = false;
}
